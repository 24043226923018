export default {
  "remote": {
    "condition-as-serialno": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applications sorted by number"])},
    "condition-as-serialno-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["By pressing this button, you will get a table with the status of applications on remote points sorted by their numbers, or a list of installations that are currently available. If you do not have supervisor rights, only remote points that you are the owner of will be displayed"])},
    "condition-as-note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applications sorted by description"])},
    "condition-as-note-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["By pressing this button, you will get a table with the status of the application on remote points, sorted according to the description of the places where they are located. If you do not have supervisor rights, only remote points that you are the owner of will be displayed"])},
    "current-serial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Currently displayed charger"])},
    "sort-by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The selection of additional chargers can be sorted by"])},
    "sort-serialNo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["numbers of chargers"])},
    "sort-or": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["or"])},
    "sort-not-owner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chargers without owner"])},
    "sort-can-be": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["they can be sorted by"])},
    "sort-note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["charger names"])},
    "read-new-set": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Load the current remote point settings"])},
    "read-new-set-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If there are changes made on another computer to the current or another remote point, by pressing this button all changes will be loaded and the display of the remote point settings will be updated"])},
    "not-owner-ship": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You do not own any application on the remote point"])},
    "save-info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save current information about this charger"])},
    "note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A brief definition of a remote point charger"])},
    "error-note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The brief definition of the charger must contain at least 5 characters"])},
    "connect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The remote point application last connected to the server"])},
    "in-time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["at time"])},
    "restart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reinstall or just restart the thin application on the remote point"])},
    "owner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The owner of the charger with the remote point application"])},
    "owner-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name or title"])},
    "owner-name-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The name of the individual or legal entity of the owner of the charger"])},
    "owner-street": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Street with house number"])},
    "owner-street-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Street and number forming part of the charger owner's address"])},
    "zip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zip code"])},
    "zip-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The zip code of the charger owner's address"])},
    "seat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Headquarters"])},
    "seat-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Residence of the owner of the charger"])},
    "identify": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identification"])},
    "identify-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identification of the owner of the charger"])},
    "person": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact person of the owner of the charger with the application at the remote point"])},
    "person-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name and surname"])},
    "person-name-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The name and surname of the person who can be contacted in connection with the charger"])},
    "person-mobil": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone"])},
    "person-mobil-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone number for contact person regarding chargers"])},
    "person-mail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mail"])},
    "person-mail-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Electronic address for the contact person regarding the charger"])},
    "wifi-allow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enable / disable shared WI-FI on the remote point"])},
    "wifi-login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login to WIFI"])},
    "wifi-login-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If the owner of the charger has allowed to share the WIFI network, you must enter the login to log in here"])},
    "wifi-pass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WIFI password"])},
    "wifi-pass-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If the owner of the charger has allowed sharing the WIFI network, enter the login password here"])},
    "wifi-is-allow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WIFI is enabled on the remote point"])},
    "wifi-not-allow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WIFI is not enabled on the remote point"])},
    "wifi-login-length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["At least 5 characters"])},
    "wifi-pass-length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["At least 5 characters"])},
    "other-set": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Additional settings, clock display, time intervals in seconds, sleep mode and data update check"])},
    "digital-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Digital clock"])},
    "global-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Return to the start of the application when idle"])},
    "control-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Checking the data update on the server"])},
    "active-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active application"])},
    "global-set": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Time in minutes"])},
    "global-set-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter the time in minutes here, which will express the interval after which the application will return to the start with the set clock if there is no activity"])},
    "control-set": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Time in minutes"])},
    "control-set-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter the time in minutes here, which will express the interval after which the application on the charger should perform a data update check on the server, performed through this Dashboard"])},
    "digital-set": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Digital clock in the introduction"])},
    "analog-set": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analogue clock in the introduction"])},
    "global-limit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The acceptable limit is from 1 to 999 minutes"])},
    "control-limit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The acceptable limit is from 1 to 999 minutes"])},
    "actice-yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The charger application is active"])},
    "actice-no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The application is not active"])},
    "map-place": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter the HTTP / HTTPS path to the tourist map"])},
    "map-path": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter the route to the hiking map, ideally with the API key and the coordinates of the charger location"])},
    "map-path-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter the HTTP / HTTPS path to the tourist map if the map is not public with an API key. Watch out for ads and other links on the map"])},
    "map-path-warn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The path to the tourist map must be a URL address (http://, https:/)"])},
    "promo-video": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter a promo-video that will be played at the request of the charger client"])},
    "promo-video-warn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If the promo-video is not specified, the application will play the video that it has marked as standard"])},
    "archa-create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create a data archive with attachments to the current charger"])},
    "archa-download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download data archive with attachments from this charger"])},
    "at-date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["created on"])},
    "at-time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["at time"])},
    "in-windows": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["directly in the Windows file browser, the contents of the ...zip file may not be displayed correctly"])},
    "archa-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Using this link, it is possible to download archive data with attachments to the current charger - use the appropriate ZIP tool, the Windows system with the downloaded package may not work properly"])}
  },
  "shots": {
    "current-point": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The currently set remote point"])},
    "upload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload the images to be displayed at the bottom of the application at the set point"])},
    "upload-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In the lower part of the application screen at a remote point, it is possible to upload a large number of images at once from the local disk, which you can additionally set so that they are displayed realistically in the application within the time limits"])},
    "move-left": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Move the current image back by one in the order of promotional images at the set remote point"])},
    "set-url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter the address of the WEB page, if it is to be displayed in the application after confirming this image with the mouse, the page will be displayed without the possibility of using it"])},
    "set-url-place": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter the WEB page address - (for example https://nazov-stranky.com or http://nazov-stranky.com if the page does not use SSL)"])},
    "set-url-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In this entry, it is possible to enter the name of the page to be opened and the client at the remote point confirms this image with the mouse. The page will be displayed without being able to use it further on the application. If you do not enter the page address, no process will be invoked after confirmation with the mouse"])},
    "url-err-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The URL address of the WEB page must start with http://, in the SSL protocol https://"])},
    "url-err-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The first level domain (com, tech, info...) must be separated by a dot at the end of the URL address."])},
    "url-err-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The first level domain (com, tech, info...) can have a maximum of 5 characters"])},
    "other-set": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Additional settings for the current image, its display in the application, display time in seconds, or physical deletion of this image"])},
    "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you confirm this icon by pressing the mouse button twice, the current promotional image will be permanently physically deleted"])},
    "limit-place": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Display limit"])},
    "limit-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In this input, it is possible to enter a value in seconds for how many seconds this promotional image should be displayed within the group - (the maximum possible time to set is up to 999 seconds)"])},
    "button-save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save the URL and view limit"])},
    "set-view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["With this icon, it is possible to set with the mouse whether this promotional image should be displayed in the application (green icon with an eye) or not (orange icon with a crossed-out eye)"])},
    "move-right": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Move the current image forward by one in the order of promotional images at the set remote point"])}
  },
  "owners": {
    "choice-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select an existing owner to view or make changes"])},
    "choice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can select or search for any owner in this selection as you are authorized to do so"])},
    "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create a new charger owner"])},
    "name-title-up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The name of the owner of the charger at the remote point, his identification"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name or name of owner"])},
    "ident": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Other owner identification"])},
    "zip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zip code"])},
    "name-place": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name or name of owner"])},
    "name-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In this entry, it is possible to enter or edit the name or title of the owner, or to enter the name or title of the new owner of the charger"])},
    "ident-place": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Other identification"])},
    "ident-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In this entry, it is possible to enter or edit another identification of the owner, it can be an organization identification number, but also any other number or alphanumeric string"])},
    "zip-place": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zip code"])},
    "zip-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The postal code is entered in this entry"])},
    "name-warn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter at least 5 valid characters"])},
    "ident-warn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter at least 3 valid characters"])},
    "zip-warn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The recommended format is 999 99"])},
    "address-title-up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address and seat of the owner of the charger at the remote point"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Street and entrance number within the owner's residence"])},
    "seat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Owner's residence"])},
    "address-place": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Street and entrance number"])},
    "address-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In this entry, it is possible to edit or enter the address of the owner, i.e. the street and number of the entrance where the owner resides"])},
    "seat-place": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Owner's residence"])},
    "seat-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In this entry, it is possible to enter or edit the owner's residence, the city and its part, or the name of the village"])},
    "address-warn-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The address must be entered correctly"])},
    "address-warn-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The address must include the name of the street and the number of the entrance"])},
    "address-warn-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The street name begins with a capital letter"])},
    "address-warn-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non-specific numbering of the entrance"])},
    "address-warn-6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The exact marking of the entrance number is missing"])},
    "seat-warn-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The city or municipality must be entered as the seat of the owner"])},
    "seat-warn-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The name of the city or village must begin with a capital letter"])},
    "seat-warn-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The name contains illegal characters"])},
    "seat-warn-6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The city or municipality must have at least two characters"])},
    "save-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save current data for this owner"])},
    "ship": {
      "choice-charger": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select the charger to be assigned ownership"])},
      "choice-charger-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In this selection, you can select or search for a charger that does not yet have ownership - by selecting a charger, ownership will be assigned to it for the currently set owner"])},
      "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel ownership"])},
      "charger": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Charger number with its textual definition"])},
      "debug": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Workbar"])},
      "remove-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["By double-clicking the mouse, you cancel the ownership of the charger listed on the right"])},
      "remove-title-off": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You are not authorized to cancel ownership of the charger"])},
      "debug-on": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["By double-clicking on the charger shown on the left, you turn on the lower green bar for debugging the application"])},
      "debug-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["By double-clicking on the charger shown on the left, you will turn off the lower green bar for debugging the application"])},
      "debug-title-off": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You are not authorized to turn on the green taskbar to the charger listed on the left for the purposes of apicing debugging"])}
    }
  },
  "account": {
    "choice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select an access account (administrator) to the central dashboard to view or make changes"])},
    "choice-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Changes can now be made to the selected access account"])},
    "create-new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create a new account (administrator) for access to the central dashboard"])},
    "note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter a brief notification - description for this access"])},
    "note-place": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A brief description of this approach"])},
    "note-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter the text that will characterize the edited approach"])},
    "note-warn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimum 10, maximum 80 characters"])},
    "name-concat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["More detailed information for contacting the administrator"])},
    "name-over": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name and surname of the administrator / owner"])},
    "type-over": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administrator / owner type of access"])},
    "mobil-over": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact cell phone"])},
    "mail-over": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact email address"])},
    "name-space": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name and surname"])},
    "name-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name and surname of the contact person, owner or administrator"])},
    "name-warn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["At least 2 words, each at least 2 characters"])},
    "type-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This data must be entered because it characterizes the access rights"])},
    "type-warn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type of access must be entered"])},
    "mobil-space": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telephone number"])},
    "mobil-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact phone number (mobile) for the administrator of the remote point"])},
    "mobil-warn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Format +420 999 999999"])},
    "mail-space": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Electronic address"])},
    "mail-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Electronic address of the owner or administrator - so-called e-mail"])},
    "mail-warn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The correct form of the address is "])},
    "name-access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Access data and connections with the currently loaded access to the dashboard"])},
    "login-over": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ussername"])},
    "pass-over": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
    "pass-conf-over": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Repeat password"])},
    "trial-over": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valid until"])},
    "active-over": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active approach"])},
    "delete-over": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete access"])},
    "login-space": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ussername"])},
    "login-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The name under which the administrator logs in to the system is entered in this entry"])},
    "login-warn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["At least 5 characters"])},
    "pass-space": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["After the name, enter the password"])},
    "pass-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If a login name was entered, then the password for logging into the dashboard must be entered in this entry"])},
    "pass-warn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eight characters (a-z, A-Z, 0-9) + another character"])},
    "pass-conf-space": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["After the name, repeat the password"])},
    "pass-conf-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If a login name was entered, then the password must be repeated in this entry, which must match the one entered on the left"])},
    "pass-conf-warn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwords must match"])},
    "trial-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If a date is entered in this entry, access is or will be valid only until that date"])},
    "trial-warn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temporary access"])},
    "delete-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The currently displayed access can be deleted, the deletion is fictitious, and can be canceled at any given moment"])},
    "active-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The currently displayed access can be set as active or inactive"])},
    "active-is": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Access is active"])},
    "active-not": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Access is not active"])},
    "delete-not": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Access exists"])},
    "delete-is": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Access is deleted"])},
    "owner-ship": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The displayed access account refers to the owner of the charger"])},
    "save-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter the current data for this access account"])}
  },
  "languages": {
    "remote-point": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current remote point"])},
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add the language of another country"])},
    "uses-all-flags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The currently set charger uses all languages, or there is an error somewhere"])},
    "no-available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For this reason, no language is currently available"])},
    "in-line-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The flags in the respective remote point application will be displayed in the order shown below"])},
    "in-line-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The language with the country flag shown below first will be considered the local language of the remote point"])},
    "in-line-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you want to change the current order, first confirm the flag whose location you need to change"])},
    "in-line-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["and then confirm the flag to which the flag displayed on the mouse cursor should be placed"])},
    "in-line-5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can also swap the flags using the arrows shown on the left and right"])},
    "move-flag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Move the flag"])},
    "move-flag-left": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["towards the left"])},
    "move-flag-right": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["towards the right"])},
    "remove-flag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel the use of this language in the remote point application"])}
  },
  "login": {
    "your-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your login name"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["name"])},
    "your-pass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your login password"])},
    "pass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["password"])},
    "view-pass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["By confirming this icon, the entered password will be displayed for three seconds"])},
    "login-verify": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I am verifying the entered data"])},
    "undef-err": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An unidentifiable error has occurred"])},
    "login-err": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Incorrect login information"])},
    "enterTo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To enter"])},
    "current": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logged in"])},
    "out": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login out"])},
    "out-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you confirm this part with the mouse, you will log out of this application"])},
    "type": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unknown user type"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supervisor"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Charger owner"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tester"])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Project developer"])},
      "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Access for a fixed period"])}
    }
  },
  "top-menu": {
    "remote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A distant point"])},
    "advertising": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Advertising"])},
    "langs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Languages"])},
    "connects": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connectors"])},
    "region": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Region"])},
    "weath": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The weather"])},
    "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Help"])},
    "accounts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Access"])}
  },
  "xxxxxxxxxxxxxxxvideo": {
    "first-start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter a promo-video that will be played at the request of the charger client"])}
  },
  "sliders": {
    "save-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload selected files"])},
    "count-files": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of uploaded files"])},
    "ext-not-allow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The file extension is not allowed"])},
    "shot-move-left": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Move the frame back by one"])},
    "shot-move-right": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Move the frame forward by one"])},
    "shot-allow-view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Display a shot at a remote point"])},
    "shot-not-view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Display of this image is disabled on the remote point"])},
    "shot-view-limit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The time limit for displaying this image in seconds"])},
    "shot-save-limit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter the time limit for displaying the image - max. 99 seconds"])},
    "shot-delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["By double-confirming the icon, the current image will be physically deleted"])},
    "direct-param": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This parameter is mandatory"])},
    "view-count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The number of shots displayed on the charger"])},
    "move-shot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I'm swapping the two shots with each other"])},
    "delete-shot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I am deleting the item after confirming the deletion twice"])},
    "max-limit-shot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The maximum display limit is 99 seconds"])},
    "limit-allow-save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can enter the limit of the image display in seconds"])},
    "save-limit-view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I write down the set limit for the confirmed shot"])},
    "set-active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The confirmed shot is set as active"])},
    "set-no-active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A confirmed shot is set as inactive"])},
    "load-files": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I load the selected files to the charger set above"])},
    "out-of-sizes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The minimum image dimensions are 1000px / 320px"])}
  },
  "switch": {
    "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["YES"])},
    "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NO"])}
  },
  "charger": {
    "offer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select another application on the remote point"])},
    "remote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A distant point"])},
    "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["contact to"])}
  },
  "lang": {
    "offer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add another language"])}
  },
  "setFlags": {
    "line-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The flags in the respective remote point application will be displayed in the order shown below. "])},
    "line-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The language with the country flag shown below first will be considered the local language of the remote point. "])},
    "line-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you want to change the current order, first confirm the flag whose position you need to change, "])},
    "line-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["and then confirm the flag to which the flag displayed on the mouse cursor should be placed."])},
    "not-avail-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The currently set remote point uses all languages. "])},
    "not-avail-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Therefore, no other language is currently available in this window."])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete language alternative"])},
    "move": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Move the flag"])},
    "left": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["direction left"])},
    "right": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["right direction"])}
  },
  "chargers": {
    "connect-01": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The remote point application last connected to the server on"])},
    "connect-02": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["at time"])},
    "save-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter current information about this charger"])},
    "restart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reinstall or just restart the thin application on a remote point"])},
    "note": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A brief definition of a remote point charger"])}
    },
    "owner": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The owner of the charger with the remote point application"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name of the owner"])},
      "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address"])},
      "zip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zip code"])},
      "seat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The city"])},
      "ico": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identification"])}
    },
    "person": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact person of the owner of the charger with the application at the remote point"])},
      "person": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name and surname"])},
      "mobil": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone"])},
      "mail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail"])}
    },
    "wifi": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enable / disable shared WI-FI on the remote point "])},
      "allow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enable WI-FI"])},
      "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login to WI-FI"])},
      "pass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WI-FI password"])},
      "title-allow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Option to enter accompanying text that can be displayed if WI-FI is enabled"])},
      "title-not-allow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Option to enter accompanying text that can be displayed if WI-FI is not enabled"])}
    },
    "other-sets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Additional settings, clock display, time intervals in seconds, sleep mode and data update check"])},
    "clock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Digital clock"])},
    "globalInterval": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Return to the start of the application when idle"])},
    "controlInterval": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Checking the data update on the server"])},
    "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active application"])},
    "map-path": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter the route to the hiking map, ideally with the API key and the coordinates of the charger location"])},
    "error": {
      "note": {
        "note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The basic description of the charger must have at least 5 characters"])}
      },
      "restart": {
        "allow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The application on the current remote point will be restarted the next time it connects to the database"])},
        "not": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A new installation on the remote point side was prevented"])}
      },
      "owner": {
        "owner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["At least 5 characters"])},
        "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["At least 5 characters"])},
        "zip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["At least 5 numbers"])},
        "seat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["At least 5 characters"])},
        "ico": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["At least 3 characters"])},
        "person": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["At least 5 characters"])},
        "mobil": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For example +421 999 999999"])},
        "mail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Format name.surname--at--domain.com"])}
      },
      "wifi": {
        "allow-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WI-FI is enabled at the charger"])},
        "allow-0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WI-FI is not allowed at the charger"])},
        "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["At least 5 characters"])},
        "pass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["At least 5 characters"])}
      },
      "digital-clock-0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Native clock"])},
      "digital-clock-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Digital clock"])},
      "globalInterval": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimum value is 3 (minutes)"])},
      "controlInterval": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimum value is 3 (minutes)"])},
      "active-0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Technical break"])},
      "active-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active application"])},
      "map-path": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The path to the tourist map must be a URL address (http://, https:/)"])}
    }
  },
  "select": {
    "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The selector is currently without an offer"])}
  },
  "access": {
    "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create a new administrator"])},
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Write the edited administrator"])},
    "note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A brief description of this approach"])},
    "name-admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name of administrator / owner"])},
    "char-right": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Characteristics of authorizations"])},
    "mobil": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mobile contact number"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact e-mail address"])},
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter your login name"])},
    "pass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter your login password"])},
    "pass-icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show password entry"])},
    "pass-confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Re-enter the password"])},
    "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This access is active"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Access is marked as deleted"])},
    "trial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trial access"])},
    "owner-necessary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select an existing or fill in a new charger owner"])},
    "owner-chargers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In addition to supervisor, developer and tester, make specific chargers available to other types of access"])},
    "owner-chargers-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select the charger to be assigned ownership for the set owner"])},
    "error": {
      "note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The basic description of the access account must have at least 5 characters"])},
      "name-admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First and last name at least 5 characters + space"])},
      "char": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Access characteristics must be entered"])},
      "mobil": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ideal number format"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Incorrect form of email address"])},
      "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter at least 5 valid characters"])},
      "pass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uppercase lowercase letters, number and other character"])},
      "pass-confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It does not match the password entered on the left"])},
      "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Access is currently active"])},
      "not-active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Access is temporarily blocked"])},
      "not-delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Access is not marked as deleted"])},
      "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Access has been marked as deleted"])},
      "trial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The requested format is"])},
      "valid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It is valid until the specified date"])}
    }
  },
  "owner": {
    "assign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Access is assigned to the owner"])},
    "exist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can select an existing owner"])},
    "new-alter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Or create a new owner"])},
    "create-new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create a new owner"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Charger owner name"])},
    "ico": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Other owner identification"])},
    "zip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Postcode"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address, street name, inventory and serial number"])},
    "seat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seat, village, city - its part"])},
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Write changes to the owners of the charger"])},
    "error": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter at least 5 valid characters"])},
      "ico": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["At least three valid characters"])},
      "zip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Format 99999 or 999 99"])},
      "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For example Street name 423/1c"])},
      "seat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["At least two characters, Name of the municipality - its part"])}
    },
    "ship": {
      "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Double-click with the mouse to cancel ownership of this charger"])},
      "debug-on": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["By double-confirming with the mouse, you turn on the debugger - the green bar in the lower part"])},
      "debug-off": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Double confirmation with the mouse will turn off the activated debugger - the green bar in the lower part will not be displayed"])}
    }
  },
  "weather": {
    "use-system": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meteorological system used"])},
    "latitude": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Latitude"])},
    "longitude": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Longitude"])},
    "note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Note"])},
    "sea-level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Height above sea level"])},
    "save-info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Write information about the weather forecast"])},
    "azimut-win": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wind direction (azimuth)"])},
    "not-access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The weather forecast is currently not available!"])},
    "cloudy-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["clear"])},
    "cloudy-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["half clear"])},
    "cloudy-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["cloudy"])},
    "cloudy-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["almost cloudy"])},
    "cloudy-5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["completely cloudy"])},
    "at-hours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hourly weather forecast"])},
    "direct-win": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wind direction"])},
    "rain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The rain"])},
    "speed-win": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wind speed"])},
    "gust-win": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gusts of wind"])},
    "press-win": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Air pressure"])},
    "snow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Snowing"])},
    "sunrise": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sunrise"])},
    "sunset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sunset"])}
  },
  "daysName": {
    "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sunday"])},
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monday"])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tuesday"])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wednesday"])},
    "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thursday"])},
    "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Friday"])},
    "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saturday"])}
  },
  "monthsName": {
    "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["January"])},
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["February"])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["March"])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["April"])},
    "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["May"])},
    "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["June"])},
    "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["July"])},
    "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["August"])},
    "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["September"])},
    "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["October"])},
    "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["November"])},
    "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["December"])}
  },
  "cms": {
    "deposit": {
      "head": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Work with CMS content and its deposit"])},
      "head-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can save the current content of this CMS to the deposit, select it from another deposit, or delete the content you created in the deposit"])},
      "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create a deposit from this CMS content"])},
      "create-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you confirm this part, you can deposit the current content of the CMS"])},
      "insert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Load a deposit into this CMS content"])},
      "insert-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can load content into this CMS from the available repositories"])},
      "choice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can select a template from the templates available in the repository and load its content into this CMS"])},
      "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete the deposit of this content"])},
      "delete-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can delete the created deposit relating to the current CMS at any time, although it is convenient to keep the content in the deposit"])},
      "not-exist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There is no deposit of this content"])},
      "not-exist-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If the deposit for the current CMS does not exist, it is advisable to preserve it and maintain it in an up-to-date state"])},
      "insert-quest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you really want to deposit the content of this CMS into a deposit ?"])},
      "insert-yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Put the content of the CMS in the deposit"])},
      "insert-no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No, I changed my mind"])},
      "read-yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Load the selected deposit into this CMS"])},
      "read-no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])},
      "delete-quest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete the content of the deposit related to this CMS ? "])},
      "delete-yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clear the deposit"])},
      "delete-no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])},
      "preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show on screen"])},
      "preview-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["By pressing this button, the content of the current CMS will be displayed in the dimensions of the set screen"])},
      "remove-all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete all CMS content"])},
      "remove-all-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Think very carefully if you want to delete all the current content of the CMS by double-confirming this button"])}
    },
    "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sorry, we are still preparing the content."])},
    "choice-flags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["From this selection of countries, it is possible to select the language in which the content is currently to be managed within the entire CMS tool."])},
    "line-move-up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Move the current line one higher in the content."])},
    "line-move-down": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In the table of contents, move the current line down by one."])},
    "line-view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Display the current row in the application."])},
    "line-not-view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do not display the current line in the application."])},
    "line-change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change the settings and parameters of the current line."])},
    "line-add-down": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Double-click on the position of this line to add another line, this line will appear below."])},
    "copy-style": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copy the line with all accessories. By accessories we understand the style settings of the line, images belonging to the copied line if they have been uploaded."])},
    "line-trash": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Double confirmation to throw the current row in the trash with the possibility of recovery."])},
    "line-recover": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restore deleted row."])},
    "win-line-modal": {
      "close-save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keep the changes made in the line and close the window."])},
      "close-save-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm the mouse to close the window with the changes made in the row settings."])},
      "close-no-save-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Double-click the mouse to close the window without saving changes in the row settings. The original setting is returned to the edited line."])}
    },
    "image": {
      "sector": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Settings for images."])},
      "img-width-l-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If there is an image in the left part of the CMS line, you can specify its width with this parameter. It is necessary to proceed in such a way that the image does not remain distorted."])},
      "img-height-l-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If there is an image in the left part of the CMS line, you can specify its height with this parameter. It is necessary to proceed in such a way that the image does not remain distorted."])},
      "img-margin-top-l-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If there is an image in the left part of the CMS line, you can use this parameter to set its offset from the upper limit of the line."])},
      "img-margin-right-l-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If there is an image in the left part of the CMS row, you can use this parameter to set its offset from the right border of the row."])},
      "img-margin-bottom-l-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If there is an image in the left part of the CMS row, you can use this parameter to set its offset from the lower border of the row."])},
      "img-margin-left-l-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If there is an image in the left part of the CMS line, you can use this parameter to set its offset from the left border of the line."])},
      "img-width-r-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If there is an image in the left part of the CMS line, you can specify its width with this parameter. It is necessary to proceed in such a way that the image does not remain distorted."])},
      "img-height-r-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If there is an image in the left part of the CMS line, you can specify its height with this parameter. It is necessary to proceed in such a way that the image does not remain distorted."])},
      "img-margin-top-r-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If there is an image in the right part of the CMS line, you can use this parameter to set its offset from the upper limit of the line."])},
      "img-margin-right-r-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If there is an image in the right part of the CMS line, you can use this parameter to set its offset from the right border of the line."])},
      "img-margin-bottom-r-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If there is an image in the right part of the CMS line, you can use this parameter to set its offset from the lower limit of the line."])},
      "img-margin-left-r-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If there is an image in the right part of the CMS line, you can use this parameter to set its offset from the left border of the line."])},
      "image-upload-l-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["With this tool, you can upload a new image to the left part. The existing image on the left will be replaced. If the left part of the CMS row contains text, it will be preserved and will reappear when the image is deleted."])},
      "image-upload-r-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["With this tool, you can upload a new image to the right section. The existing image on the right will be replaced. If the right part of the CMS line contains text, it will be preserved and will reappear when the image is deleted."])},
      "upload-l": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert the image in the left part of the line"])},
      "upload-r": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert the image in the right part of the line"])},
      "upload-l-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In the left part of the CMS row, in addition to the text, it is also possible to insert an image that you select from the local computer. It is possible to specify the width, height and offset from the line boundaries for the image."])},
      "remove-upload-l": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete the image from the left part of the row"])},
      "remove-upload-r": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete the image from the right part of the row"])},
      "remove-upload-l-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If there is an image in the left part of the line, you can delete it by double-clicking the mouse. If you have entered any text in the left part in the past, it has been preserved and will be displayed again in this part."])},
      "remove-upload-r-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If there is an image in the right part of the line, you can delete it by double-clicking the mouse. If you have entered any text in the right part in the past, it has been preserved and will be displayed again in this part."])}
    },
    "edit-line": {
      "change-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change the text"])},
      "change-text-l-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["By confirming this button, it will be possible to make interventions and changes to the text in the left part of the CMS row."])},
      "save-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
      "change-text-r-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["By confirming this button, it will be possible to make interventions and changes to the text in the right part of the CMS line."])},
      "copy-text-l-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["When using CMS (content management) to make work easier, there is an option in the left part of the line to copy already prepared text from another language in the same line and in the same part of it - that is, from another language version of the current CMS content."])},
      "copy-text-r-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["When using CMS (content management) to make work easier, there is an option in the right part of the line to copy already prepared text from another language in the same line and in the same part of it - that is, from another language version of the current CMS content."])},
      "save-text-l-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["By pressing this button, all changes will be saved and the text editing mode will be terminated. If you press the trash can icon at the very top left, the written changes, including the text content, will be reset."])},
      "no-save-text-l-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pressing the icon with the trash can will exit the text editing mode without saving the changes."])},
      "no-save-text-r-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pressing the icon with the trash can will exit the text editing mode without saving the changes."])},
      "save-text-r-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["By pressing this button, all changes will be saved and the text editing mode will be terminated. If you press the trash can icon at the very top left, the written changes, including the text content, will be reset."])},
      "change-l-r-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Swap the left and right parts of the line."])},
      "width-l-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Set the width of the left part of the line, minimum 1% / max. 99%."])},
      "width-r-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Set the width of the right part of the line, minimum 1% / max. 99%."])},
      "no-view-l-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["By confirming the icon, set so that the left part of the line is not displayed, the right part will occupy the entire line."])},
      "view-l-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["By confirming the icon to set the left part of the line to be displayed, the width of both parts of the line will be initialized to 50%."])},
      "no-view-r-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm the icon to set so that the right part of the line is not displayed, the left part will occupy the entire line."])},
      "view-r-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["By confirming the icon set so that the right part of the line is displayed, the width of both parts of the line will be initialized to 50%."])},
      "disabled-view-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It is not possible to turn off the display of all its parts in a particular row."])},
      "height-l-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If necessary, you can also set the height of the left part of the row. The height must not be less than 50px and also must not exceed the total height of the row."])},
      "height-r-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If necessary, you can also adjust the height of the right part of the row. The height must not be less than 50px and also must not exceed the total height of the row."])},
      "font-l-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["From this selector, it is possible to set a different font to be used in the left part of the line. If you need to add a completely new font, please contact the top administrator with the request."])},
      "font-r-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["From this selector, it is possible to set a different font to be used in the right part of the line. If you need to add a completely new font, please contact the top administrator with the request."])},
      "color-l-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["With the mouse, you are on the option to change the color of the text in the left part of the line. The value must always be in hexadecimal form (hexadecimal) with three or six characters (1 to 9, A to F), otherwise it is invalid."])},
      "color-r-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["With the mouse, you are on the option to change the color of the text in the right part of the line. The value must always be in hexadecimal form (hexadecimal) with three or six characters (1 to 9, A to F), otherwise it is invalid."])},
      "font-size-l-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This value gives the possibility to change the font size in the left part of the line. Of the many options, this CMS uses the em unit because it is relative with good use in responsive display. Allowed 0.5 to 4.9(em)."])},
      "font-size-r-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This value gives the possibility to change the font size in the right part of the line. Of the many options, this CMS uses the em unit because it is relative with good use in responsive display. Allowed 0.5 to 4.9(em)."])},
      "font-weight-l-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In the left part of the edited line, it is possible to set the thickness of the used font. The applications are developed so that the thickness of the font can be set as sensitively as possible."])},
      "font-weight-r-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In the right part of the edited line, it is possible to set the thickness of the used font. The applications are developed so that the thickness of the font can be set as sensitively as possible."])},
      "font-style-l-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["As for the font style, in the left part of the line you can set a normal font or italics. Other options were ignored due to poor compatibility between fonts."])},
      "font-style-r-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["As for the font style, in the right part of the line you can set a normal font or italics. Other options were ignored due to poor compatibility between fonts."])},
      "background-l-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This content management allows you to change the background color even only in the left part of the edited line. The value must always be in hexadecimal form (hexadecimal) with three or six characters (1 to 9, A to F), otherwise it is invalid. The color for only part of the line is not necessarily specified."])},
      "background-r-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This content management allows you to change the background color even only in the right part of the edited line. The value must always be in hexadecimal form (hexadecimal) with three or six characters (1 to 9, A to F), otherwise it is invalid. The color for only part of the line is not necessarily specified."])},
      "line-height-l-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["When editing the left part of the line, it is also possible to set the height of the text offset between the text lines. As a result, individual text lines will be offset vertically. This value does not need to be entered."])},
      "line-height-r-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["When editing the right part of the line, it is also possible to set the height of the text indent between the text lines. As a result, individual text lines will be offset vertically. This value does not need to be entered."])},
      "text-align-l-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In the left part of the line, we have the option to set the horizontal offset of the text. left - means to align the text to the left, right - means to align the text to the right, center - means to align the text to the center and justify - means to align the text evenly to the entire width of the element."])},
      "text-align-r-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In the right part of the line, we have the option to set the horizontal offset of the text. left - means to align the text to the left, right - means to align the text to the right, center - means to align the text to the center and justify - means to align the text evenly to the entire width of the element."])},
      "text-decor-l-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["As a bonus, you can also underline the text in the left part with the underline option. If you specify none, the text will not be underlined."])},
      "text-decor-r-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["As a bonus, you can also underline the text in the right part by selecting underline. If you specify none, the text will not be underlined."])},
      "padding-l-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The values next to this icon serve to indent the text inside the left part of the edited line. More information will be displayed if you set the mouse cursor to a specific value in the immediate vicinity of the icon."])},
      "margin-l-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The values next to this icon serve to offset the left part of the edited line from above or below. It is not possible to indent the inner part of the line from the left or right. More information will be displayed if you set the mouse cursor to a specific value in the immediate vicinity of the icon."])},
      "margin-r-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The values next to this icon serve to offset the right part of the edited line from above or below. It is not possible to indent the inner part of the line from the left or right. More information will be displayed if you set the mouse cursor to a specific value in the immediate vicinity of the icon."])},
      "padding-r-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The values next to this icon are used to offset the text inside the right part of the edited line. More information will be displayed if you set the mouse cursor to a specific value in the immediate vicinity of the icon."])},
      "padding-top-l-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This value determines how many pixels (points) the text in the left part should be offset from its upper border. If the value is empty, no top offset is set."])},
      "margin-top-l-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This value determines how many pixels (points) the left part of the line should be offset from the top of the line border. If the value is empty, no top offset is set."])},
      "margin-top-r-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This value determines how many pixels (points) the right part of the line should be offset from the top of the line border. If the value is empty, no top offset is set."])},
      "margin-bottom-l-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This value determines how many pixels (points) the left part of the line should be offset from the bottom of the line border. If the value is empty, no padding is set."])},
      "margin-bottom-r-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This value determines how many pixels (points) the right part of the line should be offset from the bottom of the line border. If the value is empty, no padding is set."])},
      "padding-right-l-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This value determines how many pixels (points) the text in the left part should be offset from its right border. If the value is empty, no right offset is set."])},
      "padding-bottom-l-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This value determines how many pixels (points) the text in the left part should be offset from its lower border. If the value is empty, no offset from the bottom side is set."])},
      "padding-left-l-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This value determines how many pixels (points) the text in the left part should be offset from its left border. If the value is empty, no left offset is set."])},
      "padding-top-r-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This value determines how many pixels (points) the text in the right part should be offset from its upper border. If the value is empty, no top offset is set."])},
      "padding-right-r-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This value determines how many pixels (points) the text in the right part should be offset from its right border. If the value is empty, no right offset is set."])},
      "padding-bottom-r-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This value determines how many pixels (points) the text in the right part should be offset from its lower border. If the value is empty, no offset from the bottom side is set."])},
      "padding-left-r-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This value determines how many pixels (points) the text in the right part should be offset from its left border. If the value is empty, no left offset is set."])},
      "height-glob-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Set the height of the entire edited line. It should not be smaller than 50 pixels (dots) and higher than 1500 pixels (dots)."])},
      "background-glob-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This value can be used to set the background color of the entire row. The value must always be in hexadecimal form (hexadecimal) with three or six characters (1 to 9, A to F), otherwise it is invalid."])},
      "margin-glob-icon-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The value next to the icon on the right can be used to set the offset of the inner containers of the row from the upper border of the row. In this context, we recommend also using the setting of the total height of the row, which can be used to offset from the lower part of the row as well."])},
      "margin-glob-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This value can be used to set the offset of the inner containers of the row from the upper border of the row. In this context, we recommend also using the setting of the total height of the row, which can be used to offset from the lower part of the row as well."])}
    },
    "view-delete": {
      "text-date-time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Line deleted in time"])},
      "active-only": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show only active rows"])},
      "active-only-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["By confirming this button, CMS lines that have been marked as deleted will also be displayed."])},
      "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Also show deleted rows"])},
      "all-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["By confirming this button, CMS lines that have been marked as deleted will also be displayed."])},
      "drop-line": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["By double confirmation, the logically deleted CMS line will be definitively deleted, this deletion can no longer be undone."])},
      "restore-line": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["By double confirmation, the logically deleted CMS line will be returned back among the lines that were not marked as deleted."])}
    }
  }
}