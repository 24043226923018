//  this.$store.commit('[...]ToVuex', [...])
//  this.$store.state.[...]




import { createStore } from 'vuex'

export default createStore({
  state: {
    oChargersToRight:     [],
    oCurrentUser:         [],
    oCurrentCharger:      [],
    oAvailableCountries:  [],
    oFlagsFromCharger:    [],
    oAccessTypes:         [],
    oChargers:            [],
    oAllChargers:         [],
    oOwners:              [],
    oOwnerShip:           [],
    oPromoVideos:         [],
    oUsersDashboard:      [],
    oEditUser:            [],

    oEMachines:           [],
    oConnectors:          [],

    //oContentManage:       [],
    currentMenuItem:      '',

    currentChargerId:     0,
    sortSerialNo:         1,


    //weather
    oGetAllow:            [],
    oGetCloudy:           [],
  },
  getters: {
  },
  mutations: {
    
    oChargersToVuex(state, oChargers) {
      state.oChargers        = oChargers
    },  
    oCurrentUserToVuex(state, oCurrentUser) {
      state.oCurrentUser        = oCurrentUser
    },  
    oCurrentChargerToVuex(state, oCurrentCharger) {
    state.oCurrentCharger       = oCurrentCharger
    },  
    currentMenuItemToVuex(state, currentMenuItem) {
      state.currentMenuItem     = currentMenuItem
    },
    oAvailableCountriesToVuex(state, oAvailableCountries) {
      state.oAvailableCountries = oAvailableCountries
    },    
    oFlagsFromChargerToVuex(state, oFlagsFromCharger) {
      state.oFlagsFromCharger   = oFlagsFromCharger
    },
    oAccessTypesToVuex(state, oAccessTypes) {
      state.oAccessTypes   = oAccessTypes
    },
    oOwnersToVuex(state, oOwners) {
      state.oOwners   = oOwners
    },
    oOwnerShipToVuex(state, oOwnerShip) {
      state.oOwnerShip   = oOwnerShip
    },
    oPromoVideosToVuex(state, oPromoVideos) {
      state.oPromoVideos   = oPromoVideos
    },
    
    
    oUsersDashboardToVuex(state, oUsersDashboard) {
      state.oUsersDashboard   = oUsersDashboard
    },
    oAllChargersToVuex(state, oAllChargers) {
      state.oAllChargers   = oAllChargers
    },    
    oEditUserToVuex(state, oEditUser) {
      state.oEditUser   = oEditUser
    },    
    
    
    
    oEMachinesToVuex(state, oEMachines) {
      state.oEMachines   = oEMachines
    },    
    oConnectorsToVuex(state, oConnectors) {
      state.oConnectors   = oConnectors
    },        

    currentChargerIdToVuex(state, currentChargerId) {
      state.currentChargerId   = currentChargerId
    },

    sortSerialNoToVuex(state, sortSerialNo) {
      state.sortSerialNo   = sortSerialNo
    },

    
    

    /*
    oContentManageToVuex(state, oContentManage) {
      state.oContentManage   = oContentManage
    },
    */    
    
//weather
    
    oGetAllowToVuex(state, oGetAllow) {
      state.oGetAllow   = oGetAllow
    },    

    oGetCloudyToVuex(state, oGetCloudy) {
      state.oGetCloudy   = oGetCloudy
    },    

    
    
  },
  actions: {
  },
  modules: {
  }
})
