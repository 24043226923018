export default {
  "remote": {
    "condition-as-serialno": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aplikace seřazené podle čísel"])},
    "condition-as-serialno-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stisknutím tohoto tlačítka získáte tabulku se stavem aplikací na vzdálených bodech seřazené podle jejich čísel, nebo seznam instalací, které jsou aktuálně k dispozici. Pokud nemáte práva supervizora, zobrazí se pouze vzdálené body kterých jste vlastníkem"])},
    "condition-as-note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aplikace seřazené podle popisu"])},
    "condition-as-note-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stisknutím tohoto tlačítka získáte tabulku se stavem aplikací na vzdálených bodech seřazené podle popisu míst kde se nacházejí. Pokud nemáte práva supervizora, zobrazí se pouze vzdálené body kterých jste vlastníkem"])},
    "current-serial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktuálně zobrazená nabíječka"])},
    "sort-by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Výběr dalších nabíječek může být setříděn podle"])},
    "sort-serialNo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["čísel nabíječek"])},
    "sort-or": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nebo"])},
    "sort-not-owner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nabíječky bez vlastníka"])},
    "sort-can-be": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mohou být setříděny podle"])},
    "sort-note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["názvů nabíječek"])},
    "read-new-set": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Načíst aktuální nastavení vzdálených bodů"])},
    "read-new-set-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pokud při aktuálním nebo i jiném vzdáleném bodě dojde ke změnám provedeným na jiném počítači, stisknutím tohoto tlačítka všechny změny budou načteny, a zobrazení nastavení vzdáleného bodu aktualizováno"])},
    "not-owner-ship": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Žádnou aplikaci na vzdáleném bodě nemáte ve vlastnictví"])},
    "save-info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zapsat aktuální informace k této nabíječce"])},
    "note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stručná definice nabíječky na vzdáleném bodě"])},
    "error-note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stručná definice nabíječky musí obsahovat alespoň 5 znaků"])},
    "connect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aplikace vzdáleného bodu se k serveru naposledy připojila"])},
    "in-time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["v čase"])},
    "restart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reinstalovat, nebo jen restartovat tenkou aplikaci na vzdáleném bodě"])},
    "owner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vlastník nabíječky s aplikací na vzdáleném bodě"])},
    "owner-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jméno nebo název"])},
    "owner-name-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jméno fyzické nebo název právnické osoby vlastníka nabíječky"])},
    "owner-street": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ulice s číslem domu"])},
    "owner-street-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ulice a číslo tvořící součást adresy vlastníka nabíječky"])},
    "zip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PSČ"])},
    "zip-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poštovní směrové číslo v adrese vlastníka nabíječky"])},
    "seat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sídlo"])},
    "seat-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sídlo vlastníka nabíječky"])},
    "identify": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identifikace"])},
    "identify-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identifikace vlastníka nabíječky"])},
    "person": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontaktní osoba vlastníka nabíječky s aplikací na vzdáleném bodě"])},
    "person-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jméno a příjmení"])},
    "person-name-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jméno a příjmení osoby, kterou lze v souvislosti s nabíječkou kontaktovat"])},
    "person-mobil": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefon"])},
    "person-mobil-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefonní číslo na kontaktní osobu ohledně nabíječky"])},
    "person-mail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mail"])},
    "person-mail-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elektronická adresa na kontaktní osobu ohledně nabíječky"])},
    "wifi-allow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Povolit / nepovolit sdílenou WI-FI na vzdáleném bodě"])},
    "wifi-login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přihlášení k WIFI"])},
    "wifi-login-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pokud vlastník nabíječky povolil sdílet WIFI síť, sem je třeba zadat login pro přihlášení"])},
    "wifi-pass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Heslo k WIFI"])},
    "wifi-pass-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pokud vlastník nabíječky povolil sdílet WIFI síť, sem je třeba zadat heslo pro přihlášení"])},
    "wifi-is-allow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WIFI je na vzdáleném bodě povolena"])},
    "wifi-not-allow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WIFI na vzdáleném bodě není povolena"])},
    "wifi-login-length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimálně 5 znaků"])},
    "wifi-pass-length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimálně 5 znaků"])},
    "other-set": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Další nastavení, zobrazení hodin, časové intervaly v sekundách, spící režim a kontrola aktualizace dat"])},
    "digital-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Digitální hodiny"])},
    "global-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Návrat do úvodu aplikace při nečinnosti"])},
    "control-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontrola aktualizace dat na serveru"])},
    "active-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktivní aplikace"])},
    "global-set": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Čas v minutách"])},
    "global-set-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zadejte sem čas v minutách, který bude vyjadřovat interval, po kterém se při nečinnosti aplikace vrátí do úvodu s nastavenými hodinami"])},
    "control-set": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Čas v minutách"])},
    "control-set-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zadejte sem čas v minutách, který bude vyjadřovat interval, po kterém má aplikace na nabíječce provést kontrolu aktualizace dat na serveru, provedených přes tento Dashboard"])},
    "digital-set": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Digitální hodiny v úvodu"])},
    "analog-set": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analogové hodiny v úvodu"])},
    "global-limit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Akceptovatelný limit je od 1 do 999 minut"])},
    "control-limit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Akceptovatelný limit je od 1 do 999 minut"])},
    "actice-yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aplikace nabíječky je aktivní"])},
    "actice-no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aplikace nabíječky není aktivní"])},
    "map-place": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zadejte HTTP / HTTPS cestu k turistické mapě"])},
    "map-path": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zadejte cestu k turistické mapě, ideální s API klíčem a souřadnicemi umístění nabíječky"])},
    "map-path-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zadejte HTTP / HTTPS cestu k turistické mapě, není-li mapa veřejná s API klíčem. Pozor na reklamy a jiné linky na mapě"])},
    "map-path-warn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cesta k turistické mapě musí být URL adresa (http://, https:/)"])},
    "promo-video": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zadejte promo-video, které se spustí na podnět klienta nabíječky"])},
    "promo-video-warn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pokud není promo-video zadáno, aplikace bude spouštět video, které má označené jako standardní"])},
    "archa-create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vytvořit archiv data s přílohami ke aktuální nabíječce"])},
    "archa-download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stáhnout archiv dat s přílohami z této nabíječky"])},
    "at-date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["vytvořeno dne"])},
    "at-time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["v čase"])},
    "in-windows": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["přímo v souborovém prohlížeči systému Windows se obsah souboru ...zip nemusí správně zobrazovat"])},
    "archa-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pomocí tohoto linku lze stáhnout archivní data s přílohami ke aktuální nabíječce - použijte příslušný ZIP nastroj, systém Windows se staženým balíkem nemusí pracovat správně"])}
  },
  "shots": {
    "current-point": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktuálně dostupný bod"])},
    "upload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nahrajte obrázky, které se mají zobrazovat v dolní části aplikácie v nastaveném bode"])},
    "upload-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do dolní části obrazovky aplikace na vzdáleném bodě lze z lokálního disku nahrát najednou i větší množství obrázků, kterým dodatečně nastavíte, aby se v aplikaci v rámci časových limitů i reálně zobrazovaly"])},
    "move-left": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktuální obrázek v poradí propagačních obrázků při nastaveném vzdáleném bodu vrátit o jeden zpět"])},
    "set-url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zadejte adresu WEB stránky, pokud se má v aplikaci zobrazit po potvrzení tohto obrázku myšky, stránka se zobrazí bez možnosti její dalšího použití"])},
    "set-url-place": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["adejte adresu WEB stránky - (např. https://nazov-stranky.com nebo http://nazov-stranky.com pokud stránka nepoužívá SSL)"])},
    "set-url-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do tohto vstupu je možné zadat názov stránky, která mi potvrdí, pokud klient na vzdáleném místě potvrdí tento obrázek myškou. Stránka sa zobrazí bez toho, aby to bylo možné dále na aplikaci používat. Ak adresu stránky nezadáte, po potvrzení myšky sa nevyvolá žádný proces"])},
    "url-err-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL adresa WEB stránky musí začínat http://, v SSL protokol https://"])},
    "url-err-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["V URL adrese bodkou musí být na konci oddelené domény prvej úrovně (com, tech, info...)"])},
    "url-err-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doména prvej úrovně (com, tech, info...) může mít maximálně 5 znaků"])},
    "other-set": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Další nastavení aktuálního obrázku, jeho zobrazení v aplikaci, čas zobrazení v sekundách, nebo fyzické zmazanie tohto obrázku"])},
    "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dvojitým stisknutím tlačítka myši tuto ikonu potvrdíte, aktuální propagační obrázek bude trvale trvale zmazaný"])},
    "limit-place": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Limit zobrazení"])},
    "limit-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do tohto vstupu je možné považovat hodnotu v sekundách, koľko sekund má byť v rámci skupiny tohoto propagačního obrázku zobrazený - (maximálně možný čas k nastavení je až 999 sekund)"])},
    "button-save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zapsat URL a limit zobrazení"])},
    "set-view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pri tejto ikonke je možné nastavit myš, či se má tento propagační obrázek v aplikaci zobrazovat (zelená ikonka s okom) nebo nie (oranžová ikonka s prečiarknutým okom)"])},
    "move-right": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktuální obrázek v poradí propagačních obrázků při nastavení vzdáleným bodem posunúť o jeden vpřed"])}
  },
  "owners": {
    "choice-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vyberte stávajícího vlastníka pro nahlédnutí, nebo provedení změn"])},
    "choice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["V tomto výběru můžete vybrat nebo vyhledat kteréhokoli vlastníka, jak máte k tomu oprávnění"])},
    "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vytvořit nového vlastníka nabíječky"])},
    "name-title-up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Název vlastníka nabíječky na vzdáleném bodě, jeho identifikace"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Název nebo jméno vlastníka"])},
    "ident": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jiná identifikace vlastníka"])},
    "zip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PSČ"])},
    "name-place": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Název nebo jméno vlastníka"])},
    "name-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do tohoto vstupu lze zadat nebo editovat jméno či název vlastníka, nebo zadat jméno nebo název nového vlastníka nabíječky"])},
    "ident-place": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jiná identifikace"])},
    "ident-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do tohoto vstupu lze zadat nebo editovat jinou identifikaci vlastníka, může to být identifikační číslo organizace, ale i jakékoli jiné číslo nebo alfanumerický řetězec"])},
    "zip-place": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PSČ"])},
    "zip-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do tohoto vstupu se zadává poštovní směrovací číslo"])},
    "name-warn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zadejte alespoň 5 platných znaků"])},
    "ident-warn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zadejte alespoň 3 platné znaky"])},
    "zip-warn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doporučený formát 999 99"])},
    "address-title-up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresa a sídlo vlastníka nabíječky na vzdáleném bodě"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ulice a číslo vchodu v rámci sídla vlastníka"])},
    "seat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sídlo vlastníka"])},
    "address-place": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ulice a číslo vchodu"])},
    "address-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do tohoto vstupu je možno editovat nebo zadat adresu vlastníka, tedy ulici a číslo vchodu kde vlastník sídlí"])},
    "seat-place": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sídlo vlastníka"])},
    "seat-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do tohoto vstupu je možno zadat nebo editovat sídlo vlastníka, město a jeho část, nebo název obce"])},
    "address-warn-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresa musí být řádně zadána"])},
    "address-warn-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresa musí obsahovat název ulice a číslo vchodu"])},
    "address-warn-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Název ulice začíná velkým písmenem"])},
    "address-warn-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nekonkrétní číslování vchodu"])},
    "address-warn-6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chybí přesné označení čísla vchodu"])},
    "seat-warn-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Město nebo obec jako sídlo vlastníka je třeba zadat"])},
    "seat-warn-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Název města nebo obce musí začínat velkým písmenem"])},
    "seat-warn-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Název obsahuje nepovolené znaky"])},
    "seat-warn-6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Město nebo obec musí mít alespoň dva znaky"])},
    "save-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zapsat aktuální údaje k tomuto vlastníkovi"])},
    "ship": {
      "choice-charger": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vyberte nabíječku, které má být přiděleno vlastnictví"])},
      "choice-charger-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["V tomto výběru můžete vybrat nebo vyhledat nabíječku ke které zatím neexistuje vlastnictví - vyjmutím nabíječky bude k ní přiřazeno vlastnictví pro aktuálně nastaveného vlastníka"])},
      "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zrušit vlastnictví"])},
      "charger": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Číslo nabíječky s její textovou definicí"])},
      "debug": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pracovní lišta"])},
      "remove-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dvojitým potvrzením myšky vlastnictví k nabíječce uvedené vpravo zrušíte"])},
      "remove-title-off": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pro zrušení vlastnického práva k nabíječce nemáte oprávnění"])},
      "debug-on": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dvojitým potvrzením myšky k nabíječce uvedené vlevo zapnete dolní zelenou lištu pro ladění aplikace"])},
      "debug-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dvojitým potvrzením myšky k nabíječce uvedené vlevo vypnete dolní zelenou lištu pro ladění aplikace"])},
      "debug-title-off": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["K zapnutí zelené pracovní lišty k nabíječce uvedené vlevo pro účely ladění apikace nemáte oprávnění"])}
    }
  },
  "account": {
    "choice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vyberte přístupový účet (správce) k centrálnímu dashboard pro zobrazení, nebo provedení změn"])},
    "choice-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ve vybraném přístupovém účtu je nyní možné provádět změny"])},
    "create-new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vytvořit nový účet (správce) pro přístup do centrálního dashboard"])},
    "note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zadejte stručnou notifikaci - popis pro tento přístup"])},
    "note-place": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stručný popis k tomuto přístupu"])},
    "note-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zadejte text, který bude editovaný přístup charakterizovat"])},
    "note-warn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimálně 10, maximálně 80 znaků"])},
    "name-concat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Podrobnější údaje pro případné kontaktování správce"])},
    "name-over": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jméno a příjmení správce - vlastníka"])},
    "type-over": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Druh přístupu správce - vlastníka"])},
    "mobil-over": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontaktní mobil"])},
    "mail-over": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontaktní elektronická adresa"])},
    "name-space": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jméno a příjmení"])},
    "name-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jméno a příjmení kontaktní osoby, vlastníka nebo správce"])},
    "name-warn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimálně 2 slova, každé alespoň 2 znaky"])},
    "type-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tento údaj je třeba zadat, protože charakterizuje oprávnění přístupu"])},
    "type-warn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Druh přístupu je třeba zadat"])},
    "mobil-space": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefonní číslo"])},
    "mobil-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontaktní telefonní číslo (mobil) na správce vzdáleného bodu"])},
    "mobil-warn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formát +420 999 999999"])},
    "mail-space": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elektronická adresa"])},
    "mail-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elektronická adresa vlastníka nebo správce - takzvaný e-mail"])},
    "mail-warn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Správný tvar adresy je "])},
    "name-access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přístupové údaje a souvislosti s aktuálně načteným přístupem do dashboard"])},
    "login-over": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přihlašovací jméno"])},
    "pass-over": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přístupové heslo"])},
    "pass-conf-over": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zopakovat heslo"])},
    "trial-over": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Platnost do"])},
    "active-over": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktivní přístup"])},
    "delete-over": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vymazat přístup"])},
    "login-space": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přihlašovací jméno"])},
    "login-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do tohoto vstupu se zadává jméno pod kterým se správce přihlásí do systému"])},
    "login-warn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimálně 5 znaků"])},
    "pass-space": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Za jméno zadat heslo"])},
    "pass-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pokud bylo zadáno přihlašovací jméno, pak do tohoto vstupu je třeba zadat heslo pro přihlášení do dashboard"])},
    "pass-warn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Osm znaků (a-z, A-Z, 0-9) + jiný znak"])},
    "pass-conf-space": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Po jménu zopakovat heslo"])},
    "pass-conf-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pokud bylo zadáno přihlašovací jméno, pak do tohoto vstupu je třeba zopakovat heslo, které se musí shodovat se zadaným vlevo"])},
    "pass-conf-warn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hesla se musí shodovat"])},
    "trial-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pokud je v tomto vstupu zadáno datum, přístup je nebo bude platný pouze do tohoto data"])},
    "trial-warn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přístup je dočasný"])},
    "delete-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktuálně zobrazený přístup lze vymazat, vymazání je fiktivní, a lze jej v danou chvíli zrušit"])},
    "active-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktuálně zobrazený přístup lze nastavit jako aktivní, nebo neaktivní"])},
    "active-is": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přístup je aktivní"])},
    "active-not": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přístup není aktivní"])},
    "delete-not": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přístup existuje"])},
    "delete-is": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přístup je vymazán"])},
    "owner-ship": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zobrazený přístupový účet se vztahuje na vlastníka nabíječky"])},
    "save-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zapsat aktuální údaje k tomuto přístupovému účtu"])}
  },
  "languages": {
    "remote-point": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktuální vzdálený bod"])},
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přidat jazyk další země"])},
    "uses-all-flags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktuálně nastavená nabíječka používá všechny jazyky, nebo někde nastala chyba"])},
    "no-available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Z tohoto důvodu žádný jazyk není v současné době k dispozici"])},
    "in-line-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vlajky se v příslušné aplikaci vzdáleného bodu budou zobrazovat v pořadí v jakém jsou zobrazeny níže"])},
    "in-line-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jazyk s vlajkou země zobrazenou níže na prvním místě bude považován za místní jazyk vzdáleného bodu"])},
    "in-line-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chcete-li aktuální pořadí změnit, nejprve potvrďte vlajku, jejíž umístění potřebujete změnit"])},
    "in-line-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["a poté potvrďte vlajku, ke které má být vlajka zobrazena na kurzoru myšky umístěna"])},
    "in-line-5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vlajky můžete zaměňovat také pomocí šipek zobrazených vlevo a vpravo"])},
    "move-flag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Posunout vlajku"])},
    "move-flag-left": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["směrem vlevo"])},
    "move-flag-right": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["směrem vpravo"])},
    "remove-flag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zrušit používání tohoto jazyka v aplikaci vzdáleného bodu"])}
  },
  "login": {
    "your-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vaše přihlašovací jméno"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["jméno"])},
    "your-pass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vaše přihlašovací heslo"])},
    "pass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["heslo"])},
    "view-pass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Potvrzením této ikonky se zadané heslo zobrazí na tři sekundy"])},
    "login-verify": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ověřuji zadané údaje"])},
    "undef-err": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nastala neidentifikovatelná chyba"])},
    "login-err": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nesprávné údaje pro přihlášení"])},
    "enterTo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vstoupit"])},
    "current": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přihlášen"])},
    "out": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odhlaste se"])},
    "out-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pokud myší potvrdíte tuto část, odhlásíte se z této aplikace"])},
    "type": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neznámý typ uživatele"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supervizor"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vlastník nabíječky"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Testér"])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vývojář projektu"])},
      "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přístup na dobu určitou"])}
    }
  },
  "top-menu": {
    "remote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vzdálený bod"])},
    "advertising": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reklama"])},
    "langs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jazyky"])},
    "connects": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konektory"])},
    "region": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Region"])},
    "weath": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Počasí"])},
    "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pomoc"])},
    "accounts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přístup"])}
  },
  "xxxxxxxxxxxxxxxxxxxxxxvideo": {
    "first-start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zadejte promo-video, které se spustí na podnět klienta nabíječky"])}
  },
  "sliders": {
    "save-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nahrát vybrané soubory"])},
    "count-files": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Počet nahraných souborů"])},
    "ext-not-allow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přípona souboru není povolena"])},
    "shot-move-left": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Posunout snímek o jeden zpět"])},
    "shot-move-right": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Posunout snímek o jeden dopředu"])},
    "shot-allow-view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zobrazovat snímek na vzdáleném bodě"])},
    "shot-not-view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zobrazování tohoto snímku je na vzdáleném bodě vypnuto"])},
    "shot-view-limit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Časový limit zobrazení tohoto snímku v sekundách"])},
    "shot-save-limit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zapsat časový limit zobrazení - max.99 sekund"])},
    "shot-delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dvojitým potvrzením ikonky bude aktuální snímka fyzicky vymazána"])},
    "direct-param": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tento parametr je povinen"])},
    "view-count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Počet snímků zobrazených na nabíječce"])},
    "move-shot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zaměňuji dva záběry mezi sebou"])},
    "delete-shot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vymazávám položku po dvojím potvrzení vymazání"])},
    "max-limit-shot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maximální limit zobrazení je 99 sekund"])},
    "limit-allow-save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Limit zobrazení záběru v sekundách můžete zapsat"])},
    "save-limit-view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zapisuji nastavený limit k potvrzenému záběru"])},
    "set-active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Potvrzený záběr se nastavuje jako aktivní"])},
    "set-no-active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Potvrzený záběr se nastavuje jako neaktivní"])},
    "load-files": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vybrané soubory načítám k nahoře nastavené nabíječce"])},
    "out-of-sizes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimální rozměry obrázku jsou 1000px/320px"])}
  },
  "switch": {
    "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ANO"])},
    "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NE"])}
  },
  "charger": {
    "offer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vyberte jinou aplikaci na vzdáleném bodě"])},
    "remote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vzdálený bod"])},
    "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kontakt na"])}
  },
  "lang": {
    "offer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přidat jazyk další země"])}
  },
  "setFlags": {
    "line-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vlajky se v příslušné aplikaci vzdáleného bodu budou zobrazovat v pořadí v jakém jsou zobrazeny níže. "])},
    "line-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jazyk s vlajkou země zobrazenou níže na prvním místě bude považován za místní jazyk vzdáleného bodu. "])},
    "line-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chcete-li aktuální pořadí změnit, nejprve potvrďte vlajku, jejíž umístění potřebujete změnit, "])},
    "line-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["a poté potvrďte vlajku, ke které má být vlajka zobrazena na kurzoru myšky umístěna."])},
    "not-avail-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktuálně nastavený vzdálený bod používá všechny jazyky. "])},
    "not-avail-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Žádný další jazyk proto v současnosti v tomto okně není k dispozici."])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vymazat jazykovou alternativu"])},
    "move": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Posunout vlajku"])},
    "left": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["směr vlevo"])},
    "right": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["směr vpravo"])}
  },
  "chargers": {
    "connect-01": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aplikace vzdáleného bodu se k serveru naposledy připojila dne"])},
    "connect-02": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["v čase"])},
    "save-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zapsat aktuální informace k této nabíječce"])},
    "restart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reinstalovat, nebo jen restartovat tenkou aplikaci na vzdáleném bodě"])},
    "note": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stručná definice nabíječky na vzdáleném bodě"])}
    },
    "owner": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vlastník nabíječky s aplikací na vzdáleném bodě"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jméno vlastníka"])},
      "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresa"])},
      "zip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PSČ"])},
      "seat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sídlo"])},
      "ico": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identifikace"])}
    },
    "person": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontaktní osoba vlastníka nabíječky s aplikací na vzdáleném bodě"])},
      "person": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jméno a příjmení"])},
      "mobil": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefon"])},
      "mail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail"])}
    },
    "wifi": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Povolit / nepovolit sdílenou WI-FI na vzdáleném bodě"])},
      "allow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Povolit WI-FI"])},
      "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přihlášení k WI-FI"])},
      "pass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Heslo k WI-FI"])},
      "title-allow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Možnost zadat doprovodný text, který se může zobrazovat pokud bude WI-FI povolena"])},
      "title-not-allow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Možnost zadat doprovodný text, který se může zobrazovat pokud WI-FI nebude povolena"])}
    },
    "other-sets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Další nastavení, zobrazení hodin, časové intervaly v sekundách, spící režim a kontrola aktualizace dat"])},
    "clock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Digitální hodiny"])},
    "globalInterval": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minuty - návrat do úvodu aplikace při nečinnosti"])},
    "controlInterval": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minuty - kontrola aktualizace dat na serveru"])},
    "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktivní aplikace"])},
    "map-path": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zadejte cestu k turistické mapě, ideální s API klíčem a souřadnicemi umístění nabíječky"])},
    "error": {
      "note": {
        "note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Základní popis nabíječky musí mít alespoň 5 znaků"])}
      },
      "restart": {
        "allow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aplikace na aktuálním vzdáleném bodě bude při dalším připojení na databázi restartována"])},
        "not": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nové instalaci na straně vzdáleného bodu bylo zabráněno"])}
      },
      "owner": {
        "owner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimálně 5 znaků"])},
        "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimálně 5 znaků"])},
        "zip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimálně 5 čísel"])},
        "seat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimálně 5 znaků"])},
        "ico": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimálně 3 znaky"])},
        "person": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimálně 5 znaků"])},
        "mobil": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Například +421 999 999999"])},
        "mail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formát jmeno.príjmeni--at--domena.cz"])}
      },
      "wifi": {
        "allow-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WI-FI je u nabíječky povolena"])},
        "allow-0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WI-FI při nabíječce není povolena"])},
        "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimálně 5 znaků"])},
        "pass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimálně 5 znaků"])}
      },
      "digital-clock-0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ručičkové hodiny"])},
      "digital-clock-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Digitální hodiny"])},
      "globalInterval": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimální hodnota jsou 3 (minuty)"])},
      "controlInterval": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimální hodnota jsou 3 (minuty)"])},
      "active-0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Technická přestávka"])},
      "active-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktivní aplikace"])},
      "map-path": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cesta k turistické mapě musí být URL adresa (http://, https:/)"])}
    }
  },
  "select": {
    "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vybírač je momentálně bez nabídky"])}
  },
  "access": {
    "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vytvořit nového správce"])},
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zapsat editovaného správce"])},
    "note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stručná charakteristika tohoto přístupu"])},
    "name-admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Název správce / vlastníka"])},
    "char-right": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Charakteristika oprávnění"])},
    "mobil": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontaktní číslo na mobil"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontaktní e-mail adresa"])},
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zadejte přihlašovací jméno"])},
    "pass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zadejte přihlašovací heslo"])},
    "pass-icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zobrazit zápis hesla"])},
    "pass-confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zopakujte zápis hesla"])},
    "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tento přístup je aktivní"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přístup je označen jako vymazaný"])},
    "trial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trial přístup"])},
    "owner-necessary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prosím vyberte stávajícího nebo vyplňte nového vlastníka nabíječky"])},
    "owner-chargers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kromě supervisora, vývojáře a testéra ostatním typům přístupu zpřístupněte konkrétní nabíječky"])},
    "owner-chargers-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vyberte nabíječku, ke které má být přiřazeno vlastnictví pro nastaveného vlastníka"])},
    "error": {
      "note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Základní popis přístupového účtu musí mít alespoň 5 znaků"])},
      "name-admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jméno a příjmení minimálně 5 znaků + mezera"])},
      "char": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Charakteristiku přístupu je třeba zadat"])},
      "mobil": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ideální formát čísla"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nesprávný tvar email adresy"])},
      "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zadejte alespoň 5 platných znaků"])},
      "pass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Velká malá písmena, číslo a jiný znak"])},
      "pass-confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neshoduje se se zadaným heslem vlevo"])},
      "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přístup je aktuálně aktivní"])},
      "not-active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přístup je dočasně zablokován"])},
      "not-delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přístup není označen za vymazán"])},
      "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přístup byl označen za vymazán"])},
      "trial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Žádaný formát je"])},
      "valid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Platnost je do zadaného data"])}
    }
  },
  "owner": {
    "assign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přístup je přiřazen k vlastníkovi"])},
    "exist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Můžete vybrat stávajícího vlastníka"])},
    "new-alter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nebo vytvořit nového vlastníka"])},
    "create-new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vytvořit nového vlastníka"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Název vlastníka nabíječky"])},
    "ico": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jiná identifikace vlastníka"])},
    "zip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poštovní směrové číslo"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresa, název ulice soupisné a řadové číslo"])},
    "seat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sídlo, obec, město - jeho část"])},
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zapsat změny u vlastníků nabíječky"])},
    "error": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zadejte alespoň 5 platných znaků"])},
      "ico": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alespoň tři platné znaky"])},
      "zip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formát 99999 nebo 999 99"])},
      "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Např. Jméno ulice 423/1c"])},
      "seat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alespoň dva znaky, Název obce - její část"])}
    },
    "ship": {
      "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dvojitým potvrzením pomocí myšky vlastnictví k této nabíječce zrušíte"])},
      "debug-on": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dvojitým potvrzením pomocí myšky zapnete debugger - zelenou lištu v dolní části"])},
      "debug-off": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dvojitým potvrzením pomocí myšky vypnete zapnutý debugger - zelená lišta v dolní části se nebude zobrazovat"])}
    }
  },
  "weather": {
    "use-system": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Používaný meteorologický systém"])},
    "latitude": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zeměpisná šířka"])},
    "longitude": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zeměpisná délka"])},
    "note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poznámka"])},
    "sea-level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nadmořská výška"])},
    "save-info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zapsat informace k předpovědi počasí"])},
    "azimut-win": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Směr větru (azimut)"])},
    "not-access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Předpověď počasí není v současnosti dostupná !"])},
    "cloudy-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["jasno"])},
    "cloudy-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["polojasno"])},
    "cloudy-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["oblačno"])},
    "cloudy-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["téměř zataženo"])},
    "cloudy-5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["zcela zataženo"])},
    "at-hours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Předpověď počasí po hodinách"])},
    "direct-win": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Směr větru"])},
    "rain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Déšť"])},
    "speed-win": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rychlost větru"])},
    "gust-win": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nárazy větru"])},
    "press-win": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tlak vzduchu"])},
    "snow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sněžení"])},
    "sunrise": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["východ slunce"])},
    "sunset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["západ slunce"])}
  },
  "daysName": {
    "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neděle"])},
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pondělí"])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Úterý"])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Středa"])},
    "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Čtvrtek"])},
    "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pátek"])},
    "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sobota"])}
  },
  "monthsName": {
    "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leden"])},
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Únor"])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Březen"])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duben"])},
    "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Květen"])},
    "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Červen"])},
    "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Červenec"])},
    "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Srpen"])},
    "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Září"])},
    "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Říjen"])},
    "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Listopad"])},
    "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prosinec"])}
  },
  "cms": {
    "deposit": {
      "head": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Práce s CMS obsahem as jeho depozitem"])},
      "head-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktuální obsah tohoto CMS můžete odložit do depozitu, vybrat z jiného depozitu, nebo v depozitu Vámi vytvořený obsah vymazat"])},
      "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vytvořit depozit z tohoto CMS obsahu"])},
      "create-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pokud potvrdíte tuto část, můžete aktuální obsah CMS vložit do depozitu"])},
      "insert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Načíst depozit do tohoto CMS obsahu"])},
      "insert-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Z depozitů, které jsou k dispozici můžete načíst obsah do tohoto CMS"])},
      "choice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ze šablon, které jsou k v depozitu k dispozici můžete vybrat šablonu a načíst její obsah do tohoto CMS4"])},
      "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vymazat depozit tohoto obsahu"])},
      "delete-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vytvořený depozit vztahující se na aktuální CMS můžete kdykoli vymazat, i když je vhodné mít obsah zachován v depozitu"])},
      "not-exist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Depozit tohoto obsahu neexistuje"])},
      "not-exist-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pokud depozit pro aktuální CMS neexistuje, je vhodné jej zachovat a udržovat v aktuálním stavu"])},
      "insert-quest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skutečně si přejete založit obsah tohoto CMS do depozitu?"])},
      "insert-yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obsah CMS vložit do depozitu"])},
      "insert-no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ne"])},
      "read-yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Načíst zvolený depozit do tohoto CMS"])},
      "read-no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ne"])},
      "delete-quest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vymazat obsah depozitu vztahující se k tomuto CMS ?"])},
      "delete-yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vymazat depozit"])},
      "delete-no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ne"])},
      "preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zobrazit na obrazovce"])},
      "preview-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stisknutím tohoto tlačítka se zobrazí obsah aktuálního CMS v rozměrech nastavené obrazovky"])},
      "remove-all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vymazat celý obsah CMS"])},
      "remove-all-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Velmi dobře si promyslete, jestli dvojitým potvrzením tohoto tlačítka chcete vymazat celý aktuální obsah CMS"])}
    },
    "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Promiňte, obsah ještě připravujeme. "])},
    "choice-flags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Z tohoto výběru zemí lze vybrat jazyk, ve kterém se aktuálně má obsah v rámci celého CMS nástroje manažovat."])},
    "line-move-up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["V obsahu přesunout aktuální řádek o jeden výše."])},
    "line-move-down": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["V obsahu přesunout aktuální řádek o jeden níže."])},
    "line-view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zobrazovat aktuální řádek v aplikaci."])},
    "line-not-view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktuální řádek v aplikaci nezobrazovat."])},
    "line-change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Změnit nastavení a parametry aktuálního řádku."])},
    "line-add-down": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dvojitým potvrzením na pozici tohoto řádku přidat další řádek, tento řádek se zobrazí níže."])},
    "copy-style": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zkopírovat řádek s celým příslušenstvím. Pod příslušenstvím rozumíme stylová nastavení řádku, obrázky příslušející ke kopírovanému řádku pokud byly nahrány."])},
    "line-trash": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dvojitým potvrzením zahodit aktuální řádek do koše s možností obnovení."])},
    "line-recover": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obnovit vymazaný řádek."])},
    "win-line-modal": {
      "close-save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ponechat provedené změny v řádku a zavřít okno"])},
      "close-save-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Potvrzením myšky zavřít okno s provedenými změnami v nastavení řádku."])},
      "close-no-save-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dvojitým potvrzením myšky zavřít okno bez zápisu změn v nastavení řádku. Do editovaného řádku se vrátí původní nastavení."])}
    },
    "image": {
      "sector": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nastavení pro obrázky."])},
      "img-width-l-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pokud se v levé části CMS řádku nachází obrázek, tímto parametrem můžete upřesnit jeho šířku. Je třeba postupovat tak, aby obrázek nezůstal zdeformovaný."])},
      "img-height-l-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pokud se v levé části CMS řádku nachází obrázek, tímto parametrem můžete upřesnit jeho výšku. Je třeba postupovat tak, aby obrázek nezůstal zdeformovaný."])},
      "img-margin-top-l-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pokud se v levé části CMS řádku nachází obrázek, tímto parametrem můžete nastavit jeho odsazení od horní hranice řádku."])},
      "img-margin-right-l-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pokud se v levé části CMS řádku nachází obrázek, tímto parametrem můžete nastavit jeho odsazení od pravé hranice řádku."])},
      "img-margin-bottom-l-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pokud se v levé části CMS řádku nachází obrázek, tímto parametrem můžete nastavit jeho odsazení od dolní hranice řádku."])},
      "img-margin-left-l-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pokud se v levé části CMS řádku nachází obrázek, tímto parametrem můžete nastavit jeho odsazení od levé hranice řádku."])},
      "img-width-r-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pokud se v levé části CMS řádku nachází obrázek, tímto parametrem můžete upřesnit jeho šířku. Je třeba postupovat tak, aby obrázek nezůstal zdeformovaný."])},
      "img-height-r-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pokud se v levé části CMS řádku nachází obrázek, tímto parametrem můžete upřesnit jeho výšku. Je třeba postupovat tak, aby obrázek nezůstal zdeformovaný."])},
      "img-margin-top-r-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pokud se v pravé části CMS řádku nachází obrázek, tímto parametrem můžete nastavit jeho odsazení od horní hranice řádku."])},
      "img-margin-right-r-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pokud se v pravé části CMS řádku nachází obrázek, tímto parametrem můžete nastavit jeho odsazení od pravé hranice řádku."])},
      "img-margin-bottom-r-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pokud se v pravé části CMS řádku nachází obrázek, tímto parametrem můžete nastavit jeho odsazení od dolní hranice řádku."])},
      "img-margin-left-r-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pokud se v pravé části CMS řádku nachází obrázek, tímto parametrem můžete nastavit jeho odsazení od levé hranice řádku."])},
      "image-upload-l-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tímto nástrojem můžete do levé části nahrát nový obrázek. Stávající obrázek v levé části bude nahrazen. Pokud levá část CMS řádku obsahuje text, tento zůstane zachován a při vymazání obrázku se znovu zobrazí."])},
      "image-upload-r-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tímto nástrojem můžete do pravé části nahrát nový obrázek. Stávající obrázek v pravé části bude nahrazen. Pokud pravá část CMS řádku obsahuje text, tento zůstane zachován a při vymazání obrázku se znovu zobrazí."])},
      "upload-l": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vložit obrázek do levé části řádku"])},
      "upload-r": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vložit obrázek do pravé části řádku"])},
      "upload-l-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do levé části CMS řádku je kromě textu možné vložit také obrázek, který vyberete z lokálního počítače. K obrázku lze určit šířku výšku a odsazení od hranic řádku."])},
      "remove-upload-l": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vymazat obrázek z levé části řádku"])},
      "remove-upload-r": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vymazat obrázek z pravé části řádku"])},
      "remove-upload-l-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pokud se v levé části řádku nachází obrázek, můžete jej dvojitým potvrzením myšky vymazat. Pokud jste do levé části v minulosti zadali jakýkoli text, zůstal zachován a bude se opět v této části zobrazovat."])},
      "remove-upload-r-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pokud se v pravé části řádku nachází obrázek, můžete jej dvojitým potvrzením myšky vymazat. Pokud jste do pravé části v minulosti zadali jakýkoli text, zůstal zachován a bude se opět v této části zobrazovat."])}
    },
    "edit-line": {
      "change-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Změnit text"])},
      "change-text-l-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Potvrzením tohoto tlačítka bude umožněno provádět do textu v levé části CMS řádku zásahy a změny."])},
      "save-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zapsat"])},
      "change-text-r-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Potvrzením tohoto tlačítka bude umožněno provádět do textu v pravé části CMS řádku zásahy a změny."])},
      "copy-text-l-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Při používání CMS (obsahového managementu) pro usnadnění práce je zde v levé části řádku možnost kopírovat již vypracovaný text z jiného jazyka ve stejném řádku a ve stejné jeho části - tedy z jiné jazykové verze aktuálního obsahu CMS."])},
      "copy-text-r-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Při používání CMS (obsahového managementu) pro usnadnění práce je zde v pravé části řádku možnost kopírovat již vypracovaný text z jiného jazyka ve stejném řádku a ve stejné jeho části - tedy z jiné jazykové verze aktuálního obsahu CMS."])},
      "save-text-l-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stisknutím tohoto tlačítka budou všechny změny zapsány a režim editování textu ukončen. Pokud stisknete ikonu odpadkového koše zcela vlevo nahoře, zapsané změny včetně textového obsahu budou vynulovány."])},
      "no-save-text-l-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stisknutím ikony s odpadkovým košem bude režim editování textu ukončen bez zápisu změn."])},
      "no-save-text-r-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stisknutím ikony s odpadkovým košem bude režim editování textu ukončen bez zápisu změn."])},
      "save-text-r-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stisknutím tohoto tlačítka budou všechny změny zapsány a režim editování textu ukončen. Pokud stisknete ikonu odpadkového koše zcela vlevo nahoře, zapsané změny včetně textového obsahu budou vynulovány."])},
      "change-l-r-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zaměnit levou a pravou část řádku mezi sebou."])},
      "width-l-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nastavte šířku levé části řádku, minimálně 1%/max. 99%."])},
      "width-r-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nastavte šířku pravé části řádku, minimálně 1%/max. 99%."])},
      "no-view-l-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Potvrzením ikony nastavit, aby se levá část řádku nezobrazovala, pravá část bude zabírat celý řádek."])},
      "view-l-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Potvrzením ikony nastavit, aby se levá část řádku zobrazovala, šířka obou částí řádku bude inicializována na 50%."])},
      "no-view-r-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Potvrzením ikony nastavit, aby se pravá část řádku nezobrazovala, levá část bude zabírat celý řádek."])},
      "view-r-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Potvrzením ikony nastavit, aby se pravá část řádku zobrazovala, šířka obou částí řádku bude inicializována na 50%."])},
      "disabled-view-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["V konkrétním řádku nelze vypnout zobrazení všech jeho částí."])},
      "height-l-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["V případě potřeby si můžete nastavit také výšku levé části řádku. Výška nesmí být méně než 50px a také nesmí přesahovat celkovou výšku řádku."])},
      "height-r-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["V případě potřeby si můžete nastavit také výšku pravé části řádku. Výška nesmí být méně než 50px a také nesmí přesahovat celkovou výšku řádku."])},
      "font-l-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Z tohoto vybírače lze nastavit jiný font, který má být v levé části řádku použit. Pokud potřebujete přidat zcela nový font, obraťte se s požadavkem na nejvyššího administrátora."])},
      "font-r-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Z tohoto vybírače lze nastavit jiný font, který má být v pravé části řádku použit. Pokud potřebujete přidat zcela nový font, obraťte se s požadavkem na nejvyššího administrátora."])},
      "color-l-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Myškou se nacházíte na možnosti měnit barvu textu v levé části řádku. Hodnota musí být vždy v hexadecimálním tvaru (šestnáctková soustava) se třemi nebo šesti znaky (1 až 9, A až F), jinak je neplatná."])},
      "color-r-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Myškou se nacházíte na možnosti měnit barvu textu v pravé části řádku. Hodnota musí být vždy v hexadecimálním tvaru (šestnáctková soustava) se třemi nebo šesti znaky (1 až 9, A až F), jinak je neplatná."])},
      "font-size-l-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tato hodnota dává možnost změny velikosti písma v levé části řádku. Z množství možností tento CMS používá jednotku em, protože je relativní s dobrým použitím při responzivním zobrazování. Povoleno 0.5 až 4.9(em)."])},
      "font-size-r-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tato hodnota dává možnost změny velikosti písma v pravé části řádku. Z množství možností tento CMS používá jednotku em, protože je relativní s dobrým použitím při responzivním zobrazování. Povoleno 0.5 až 4.9(em)."])},
      "font-weight-l-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["V levé části editovaného řádku je možnost nastavit také tloušťku používaného písma. Aplikace jsou vyvinuty tak, aby tloušťku písma bylo možno nastavit co nejcitlivěji."])},
      "font-weight-r-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["V pravé části editovaného řádku je možnost nastavit také tloušťku používaného písma. Aplikace jsou vyvinuty tak, aby tloušťku písma bylo možno nastavit co nejcitlivěji."])},
      "font-style-l-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Co se týče stylu písma, v levé části řádku můžete nastavit normální písmo nebo kurzívu (italic). Jiné možnosti byly z důvodu nízké kompatibility mezi fonty ignorovány."])},
      "font-style-r-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Co se týče stylu písma, v pravé části řádku můžete nastavit normální písmo nebo kurzívu (italic). Jiné možnosti byly z důvodu nízké kompatibility mezi fonty ignorovány."])},
      "background-l-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tento management obsahu umožňuje změnit barvu pozadí i jen v levé části editovaného řádku. Hodnota musí být vždy v hexadecimálním tvaru (šestnáctková soustava) se třemi nebo šesti znaky (1 až 9, A až F), jinak je neplatná. Barva jen pro část řádku nemusí být nutně zadána."])},
      "background-r-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tento management obsahu umožňuje změnit barvu pozadí i jen v pravé části editovaného řádku. Hodnota musí být vždy v hexadecimálním tvaru (šestnáctková soustava) se třemi nebo šesti znaky (1 až 9, A až F), jinak je neplatná. Barva jen pro část řádku nemusí být nutně zadána."])},
      "line-height-l-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Při editování levé části řádku je možnost nastavit i výšku odsazení textu mezi textovými řádky. Výsledkem bude odsazení jednotlivých textových řádků od sebe na výšku. Tuto hodnotu není třeba zadávat."])},
      "line-height-r-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Při editování pravé části řádku je možnost nastavit i výšku odsazení textu mezi textovými řádky. Výsledkem bude odsazení jednotlivých textových řádků od sebe na výšku. Tuto hodnotu není třeba zadávat."])},
      "text-align-l-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["V levé části řádku máme možnost nastavit i vodorovné odsazení textu. left - znamená zarovnat text vlevo, right - znamená zarovnat text vpravo, centr - znamená zarovnat text na střed a justify - znamená zarovnat text rovnoměrně na celou šířku elementu."])},
      "text-align-r-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["V pravé části řádku máme možnost nastavit i vodorovné odsazení textu. left - znamená zarovnat text vlevo, right - znamená zarovnat text vpravo, centr - znamená zarovnat text na střed a justify - znamená zarovnat text rovnoměrně na celou šířku elementu."])},
      "text-decor-l-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jako bonus můžete text v levé části ještě podtrhnout volbou underline. Zadáte-li none, text nebude podtržen."])},
      "text-decor-r-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jako bonus můžete text v pravé části ještě podtrhnout volbou underline. Zadáte-li none, text nebude podtržen."])},
      "padding-l-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hodnoty vedle této ikonky slouží k odsazení textu uvnitř levé části editovaného řádku. Více informací se zobrazí pokud nastavíte kurzor myšky na konkrétní hodnotu v přímé blízkosti ikonky."])},
      "margin-l-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hodnoty vedle této ikonky slouží k odsazení levé části editovaného řádku shora nebo zdola. Sleva nebo odprava nelze vnitřní část řádku odsadit. Více informací se zobrazí pokud nastavíte kurzor myšky na konkrétní hodnotu v přímé blízkosti ikonky."])},
      "margin-r-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hodnoty vedle této ikonky slouží k odsazení pravé části editovaného řádku shora nebo zdola. Sleva nebo odprava nelze vnitřní část řádku odsadit. Více informací se zobrazí pokud nastavíte kurzor myšky na konkrétní hodnotu v přímé blízkosti ikonky."])},
      "padding-r-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hodnoty vedle této ikonky slouží k odsazení textu uvnitř pravé části editovaného řádku. Více informací se zobrazí pokud nastavíte kurzor myšky na konkrétní hodnotu v přímé blízkosti ikonky."])},
      "padding-top-l-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tato hodnota určuje, o kolik pixelů (bodů) má být text v levé části od její horní hranice odsazen. Pokud je hodnota prázdná, žádné odsazení shora není nastaveno."])},
      "margin-top-l-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tato hodnota určuje, o kolik pixelů (bodů) má být levá část řádku odsazena směrem od hora hranice řádku. Pokud je hodnota prázdná, žádné odsazení shora není nastaveno."])},
      "margin-top-r-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tato hodnota určuje, o kolik pixelů (bodů) má být pravá část řádku odsazena směrem od hora hranice řádku. Pokud je hodnota prázdná, žádné odsazení shora není nastaveno."])},
      "margin-bottom-l-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tato hodnota určuje, o kolik pixelů (bodů) má být levá část řádku odsazena směrem zdola hranice řádku. Pokud je hodnota prázdná, žádné odsazení zdola není nastaveno."])},
      "margin-bottom-r-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tato hodnota určuje, o kolik pixelů (bodů) má být pravá část řádku odsazena směrem zdola hranice řádku. Pokud je hodnota prázdná, žádné odsazení zdola není nastaveno."])},
      "padding-right-l-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tato hodnota určuje, o kolik pixelů (bodů) má být text v levé části od její pravé hranice odsazen. Pokud je hodnota prázdná, žádné odsazení z pravé strany není nastaveno."])},
      "padding-bottom-l-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tato hodnota určuje, o kolik pixelů (bodů) má být text v levé části od její dolní hranice odsazen. Pokud je hodnota prázdná, žádné odsazení z dolní strany není nastaveno."])},
      "padding-left-l-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tato hodnota určuje, o kolik pixelů (bodů) má být text v levé části od její levé hranice odsazen. Pokud je hodnota prázdná, žádné odsazení z levé strany není nastaveno."])},
      "padding-top-r-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tato hodnota určuje, o kolik pixelů (bodů) má být text v pravé části od její horní hranice odsazen. Pokud je hodnota prázdná, žádné odsazení shora není nastaveno."])},
      "padding-right-r-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tato hodnota určuje, o kolik pixelů (bodů) má být text v pravé části od její pravé hranice odsazen. Pokud je hodnota prázdná, žádné odsazení z pravé strany není nastaveno."])},
      "padding-bottom-r-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tato hodnota určuje, o kolik pixelů (bodů) má být text v pravé části od její dolní hranice odsazen. Pokud je hodnota prázdná, žádné odsazení z dolní strany není nastaveno."])},
      "padding-left-r-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tato hodnota určuje, o kolik pixelů (bodů) má být text v pravé části od její levé hranice odsazen. Pokud je hodnota prázdná, žádné odsazení z levé strany není nastaveno."])},
      "height-glob-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nastavit výšku celého editovaného řádku. Neměla by být menší než 50 pixelů (bodů) a vyšší než 1500 pixelů (bodů)."])},
      "background-glob-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Touto hodnotou lze nastavit barvu pozadí celého řádku. Hodnota musí být vždy v hexadecimálním tvaru (šestnáctková soustava) se třemi nebo šesti znaky (1 až 9, A až F), jinak je neplatná."])},
      "margin-glob-icon-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hodnotou vedle ikony vpravo lze nastavit odsazení vnitřních kontejnerů řádku od horní hranice řádku. V tomto kontextu doporučujeme použít i nastavení celkové výšky řádku, čímž lze dosáhnout odsazení i od dolní části řádku."])},
      "margin-glob-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Touto hodnotou lze nastavit odsazení vnitřních kontejnerů řádku od horní hranice řádku. V tomto kontextu doporučujeme použít i nastavení celkové výšky řádku, čímž lze dosáhnout odsazení i od dolní části řádku."])}
    },
    "view-delete": {
      "text-date-time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Řádek vymazán v čase"])},
      "active-only": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zobrazit pouze aktivní řádky"])},
      "active-only-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Potvrzením tohoto tlačítka se zobrazí i CMS řádky, které byly označeny jako vymazány."])},
      "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zobrazit také vymazané řádky"])},
      "all-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Potvrzením tohoto tlačítka se zobrazí i CMS řádky, které byly označeny jako vymazány."])},
      "drop-line": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dvojitým potvrzením logicky vymazaný CMS řádek bude definitivně odstraněn, toto smazání již nelze vrátit zpět."])},
      "restore-line": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dvojitým potvrzením logicky vymazaný CMS řádek bude vrácen zpět mezi řádky, které nebyly označeny jako vymazány."])}
    }
  }
}